import { useCallback, useEffect, useRef } from 'react';

export const useThrottleClick = (
	onClick: (event: React.UIEvent) => void,
	throttleTime: number = 300,
	options = {
		isStopPropagation: true
	}
) => {
	const clickTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const isThrottleTime = useRef<boolean>(false);
	useEffect(() => {
		return () => {
			isThrottleTime && clearTimeout(clickTimeoutRef.current);
		}
	}, [])

	return useCallback((event: React.UIEvent) => {
		if (options?.isStopPropagation) {
			event.stopPropagation();
		}

		if (isThrottleTime.current) {
			return;
		}

		isThrottleTime.current = true;
		onClick(event);

		clickTimeoutRef.current = setTimeout(() => {
			isThrottleTime.current = false;
		}, throttleTime)
	}, [onClick, throttleTime, options])
}