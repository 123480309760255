const webStorageKeys = {
	'ME_PAGE_SHOW_BACK': 'me-page-show-back',
	'ROUTER_MESSAGE_BACK': 'router:message-back',
	'ROUTER_MY_BETS_BACK': 'router:my-bets-back',
	'ROUTER_HISTORY_BACK': 'router:history-back',
	'ROUTER_RESULT_BACK': 'router:result-back',
	'ROUTER_HOW_TO_USE_BACK': 'router:how-to-use-back',
	'HAS_SHOWN_NEWBIE_TUTORIAL_INFO': 'persist:has-shown-newbie-tutorial-info',
	'HAS_SHOWN_MARS_UPDATE_TOOLTIP_IN_APPBAR': 'persist:has-shown-mars-update-tooltip-in-appbar',
	'HAS_SHOWN_MARS_UPDATE_TOOLTIP_IN_HEADER': 'persist:has-shown-mars-update-tooltip-in-header',
	'IS_SHOW_REVISION_NOTICE': 'persist:is-show-revision-notice',
	'HAS_SHOWN_GALAXY_TUTORIAL': 'persist:has-shown-galaxy-tutorial',
	'FLOATSTREAMER': 'floatStreamer',
	'FLOATVIRTUALWIDGET': 'floatVirtualWidget',
	'HAS_SHOWN_BACK_TO_MATCH_TIP': 'persist:has-shown-back-to-match-tip',
	'HAS_SHOWN_OLD_STATEMENT_ALERT': 'persist:has-shown-old-statement_alert',
} as const;

export default webStorageKeys;
