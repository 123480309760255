import { useEffect, useRef, useState } from 'react';
import { useGetSiteConfigs } from '@/hooks/query/use-site-configs';
import Script from 'next/script'

export const GTMProvider = () => {
	const { SiteConfigsData } = useGetSiteConfigs();
	return (
		<>
			{
				!!SiteConfigsData?.GalaxyGAId &&
				<>
					<Script id="google-analytics-js" async src={`https://www.googletagmanager.com/gtag/js?id=${SiteConfigsData?.GalaxyGAId}`}>
					</Script>
					<Script id="google-analytics">
						{
							`window.dataLayer = window.dataLayer || [];
								window.dataLayer.push({
									'GAID': '${SiteConfigsData?.GalaxyGAId}'
								});`
						}
					</Script>
				</>
			}
		</>
	)
};