import { useAppearanceStore } from '@/store/appearance';
import { useAccountInfo } from '@/hooks/query/account';
import { ViewEnum } from '@/types';
import { useEffect, useRef, useState } from 'react';
import { useExternalScript } from '@nf/utils-common/hooks';
import { useGetSiteConfigs } from '@/hooks/query/use-site-configs';

const analyseKey = 'nextanalyse';
export const useIsNextAnalyseEnabled = () => {
	const viewFromStore = useAppearanceStore(state => state.view);
	const { user } = useAccountInfo();
	const { SiteConfigsData } = useGetSiteConfigs();
	const [isDisabled, setIsDisabled] = useState<boolean>(!user || user.ActStatus !== 2 || viewFromStore !== ViewEnum.mars || !SiteConfigsData?.EnableNextAnalyse || !SiteConfigsData?.BehaviorCatcherEntry);
	useEffect(() => {
		const currentlyIsDisabled = !user || user.ActStatus !== 2 || viewFromStore !== ViewEnum.mars || !SiteConfigsData?.EnableNextAnalyse || !SiteConfigsData?.BehaviorCatcherEntry;
		if (currentlyIsDisabled !== isDisabled) {
			setIsDisabled(currentlyIsDisabled);
		}
	}, [viewFromStore, user?.ActStatus, SiteConfigsData?.EnableNextAnalyse, SiteConfigsData?.BehaviorCatcherEntry]);
	return !isDisabled;
};
export const NextAnalyseProvider = () => {
	const { user } = useAccountInfo();
	const isNextAnalyseEnabled = useIsNextAnalyseEnabled();
	const { SiteConfigsData } = useGetSiteConfigs();
	useExternalScript(SiteConfigsData?.BehaviorCatcherEntry || '');
	
	const attachProfileInLocalStorage = () => {
		const canNotActive = !isNextAnalyseEnabled || typeof localStorage.setItem !== 'function';
		if (canNotActive) {
			return;
		}
		const currentProfile = localStorage.getItem(analyseKey);
		try {
			const profile = !currentProfile ? {} : JSON.parse(currentProfile);
			if (profile['userId'] !== user?.ID) {
				profile['userId'] = user?.ID;
			}
			const siteId = parseInt(SiteConfigsData?.GetMessageID ?? '0');
			if (profile['siteId'] !== siteId) {
				profile['siteId'] = siteId;
			}
			localStorage.setItem(analyseKey, JSON.stringify(profile));
		} catch (exception) {
			console.error('Unexpected situation happened', exception);
		}
	};
	useEffect(attachProfileInLocalStorage, []);
	useEffect(attachProfileInLocalStorage, [isNextAnalyseEnabled, user?.ID, SiteConfigsData?.GetMessageID]);

	return null;
};
export const NextAnalyseCleaner = () => {
	useEffect(() => {
		try {
			const currentProfile = localStorage.getItem(analyseKey);
			if (currentProfile !== null) {
				localStorage.removeItem(analyseKey);
				window.location.reload();
			}
		} catch (exception) {
			console.error('Something wrong when cleaning existing next-analyse profile', exception);
		}
	}, []);
	return null;
};
export const useAnalyseAttribute = (key: string) => {
	const isNextAnalyseEnabled = useIsNextAnalyseEnabled();
	const viewFromStore = useAppearanceStore(state => state.view);
	const taggedElement = useRef<any>(null);
	const attachNextAnalyse = () => {
		const canNotActive = !isNextAnalyseEnabled || !key || !taggedElement.current || typeof taggedElement.current.setAttribute !== 'function';
		if (canNotActive) {
			return;
		}
		const hasNextAnalyze = taggedElement.current.hasAttribute(analyseKey);
		if (viewFromStore === ViewEnum.mars && !hasNextAnalyze) {
			taggedElement.current.setAttribute(analyseKey, key);
		} else if (viewFromStore !== ViewEnum.mars && hasNextAnalyze) {
			taggedElement.current.removeAttribute(analyseKey);
		}
	};
	useEffect(attachNextAnalyse, []);
	useEffect(attachNextAnalyse, [taggedElement.current, isNextAnalyseEnabled]);
	return taggedElement;
};
export const useAnalyseAttributeWithToggle = (isActived: boolean, keys: string[]) => {
	const isNextAnalyseEnabled = useIsNextAnalyseEnabled();
	const viewFromStore = useAppearanceStore(state => state.view);
	const taggedElement = useRef<any>(null);
	const attachNextAnalyse = () => {
		const canNotActive = !isNextAnalyseEnabled || !Array.isArray(keys) || !taggedElement.current || typeof taggedElement.current.setAttribute !== 'function';
		if (canNotActive) {
			return;
		}
		const hasNextAnalyze = taggedElement.current.hasAttribute(analyseKey);
		if (viewFromStore === ViewEnum.mars) {
			const valueShouldBe = keys[isActived ? 0 : 1];
			if (!hasNextAnalyze || taggedElement.current.getAttribute(analyseKey) !== valueShouldBe) {
				taggedElement.current.setAttribute(analyseKey, valueShouldBe);
			}
		} else if (hasNextAnalyze) {
			taggedElement.current.removeAttribute(analyseKey);
		}
	};
	useEffect(attachNextAnalyse, []);
	useEffect(attachNextAnalyse, [taggedElement.current, isActived, isNextAnalyseEnabled]);
	return taggedElement;
};