'use client';

import { useEffect } from 'react';
import { useGetSiteConfigs } from '@/hooks/query/use-site-configs';
import { useSiteStore } from '@/store/site';

export const KafkaProvider = () => {
	const { SiteConfigsData } = useGetSiteConfigs();
	const setEnableKafka = useSiteStore(state => state.setEnableKafka);

	useEffect(() => {
		if (SiteConfigsData?.EnableKafka) {
			setEnableKafka(SiteConfigsData?.EnableKafka);
		}
	}, [SiteConfigsData?.EnableKafka]);

	return null;
};