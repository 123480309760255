interface ErrorData {
	code?: string;
	digest?: string;
}

export class CustomError extends Error {
	statusCode = 400;
	errorData: ErrorData | undefined = undefined;

	constructor(message: string, errorData?: ErrorData) {
		super(message);
		this.errorData = errorData;

		Object.setPrototypeOf(this, CustomError.prototype);
	}

	getErrorMessage() {
		return this.message;
	}

	getErrorData() {
		return this.errorData;
	}
}