import { useState, useEffect } from 'react';
import { Observable } from 'rxjs';

export function useObservableState<T>(observable$: Observable<T>): T | undefined;
export function useObservableState<T>(observable$: Observable<T>, initialValue: T): T;
export function useObservableState<T>(
	observable$: Observable<T>,
	initialValue?: T
): T | undefined {
	const [state, update] = useState<T | undefined>(initialValue);

	useEffect(() => {
		const subscription = observable$.subscribe(update);
		return () => subscription.unsubscribe();
	}, [observable$]);

	return state;
};