import { useEffect, useRef } from 'react';

export function useInterval(
	callback: () => void, 
	delay: number | null,
	options: { 
		immediate?: boolean 
	} = {}
) {
	const savedCallback = useRef(callback);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (!delay || delay < 0) {
			return;
		}

		function tick() {
			savedCallback.current();
		}

		if (options.immediate) {
			tick();
		}

		const id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay, options.immediate]);
}