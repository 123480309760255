'use client';

import { useTransition } from 'react';
import { usePathname } from '@/navigation';

import { useSiteStore } from '@/store/site';
import { useAuthActions, useAuthData } from '@/store/auth';
import { Maybe, identity } from '@nf/utils-common/fpw/monadic';
import { useInterval } from '@nf/utils-common/hooks';
import { useAccountError } from '@/hooks';
import { authService } from '@/services/auth.service';
import { getSiteIgnoredCheckPaths } from '@/services/settings.service';
import { extendUserSessionExpiry } from '@nf/services/actions/auth/session';

const loginCheckingPeriod = 60 * 1000;
const ignoredCheckPaths = getSiteIgnoredCheckPaths();

export const useSyncAccountAuth = (userToken: string | undefined, isBefore: boolean) => {
	const [isPending, startTransition] = useTransition();
	const pathname = usePathname();
	const apiDomain = useSiteStore(state => state.apiDomain);
	const { userName, siteName, uuid } = useAuthData() ?? {};
	const { updateAuthToken } = useAuthActions();
	const { handleAccountError } = useAccountError();
	
	const isIgnoredPath = ignoredCheckPaths.includes(pathname);
	const hasUserData = userName && siteName && uuid;
	const canSync = !isBefore && 
		!isIgnoredPath && 
		userToken && 
		hasUserData && 
		apiDomain.ctcdDomain;

	const extendUserToken = () => {
		if (!hasUserData) return;

		startTransition(async () => {
			try {
				const extendResponse = await extendUserSessionExpiry({
					name: userName,
					site: siteName,
					uuid
				});

				if (!extendResponse.success) {
					throw new Error('Error: Extend token failed from redis!');
				}
			} catch (error: any) {
				console.error(error);
			}
		});
	};

	useInterval(
		() => {
			Maybe(userToken).fork({
				just: (token: string) => {
					authService.invokeLoginCheckIn(token, apiDomain.ctcdDomain).subscribe({
						next: async (result) => {
							const data = await result.data;

							// Update the user's token
							Maybe(result.jwtToken).fold(
								identity,
								(jwtToken: string) => {
									updateAuthToken(jwtToken);
								}
							);

							// Extend the user token in Redis
							extendUserToken();
							data.ErrorCode && handleAccountError(String(data.ErrorCode), data.ErrorMsg, data.Data?.LogId);
						},
						error: err => {
							console.error('LoginCheckIn Error:', err);
						}
					});
				},
				none: () => {}
			});
		}, 
		canSync ? loginCheckingPeriod : null,
		{ immediate: true }
	);

	return null;
};