'use client';

import { useEffect } from 'react';

import { useSiteStore } from '@/store/site';

export const ServerGroupClientProvider = ({ ServerGroup }: { ServerGroup : string }) => {
	const [setServerGroup] = useSiteStore(state => [state.setServerGroup]);
	
	useEffect(() => {
		setServerGroup(ServerGroup);
	}, [ServerGroup]);
	
	return null;
};