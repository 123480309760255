'use client';

import { useAtomValue } from 'jotai';

import { visitorAtom } from '@/atoms/visitor';
import { useSyncAccountAuth } from './hooks/use-sync-account-auth';
import { useSyncOddsToken } from './hooks/use-sync-odds-token';

export const SyncProvider = ({ authToken }: { authToken: string | undefined }) => {
	const visitorInfo = useAtomValue(visitorAtom);
	const isBefore = !Boolean(authToken);
	const userTokenForOdds = isBefore ? visitorInfo?.AccessToken : authToken;

	useSyncAccountAuth(authToken, isBefore);
	useSyncOddsToken(userTokenForOdds, isBefore);

	return null;
};