import { useState, useRef } from 'react';

export function useLocalStorage<T>(
	key: string, 
	initialValue: T
): [T, (value: T) => void, () => void] {
	const initializer = useRef((key: string) => {
		if (typeof window === 'undefined') {
			return initialValue;
		}

		try {
			const storedItem = window.localStorage.getItem(key);

			if (storedItem) {
				return JSON.parse(storedItem);
			} else {
				initialValue && window.localStorage.setItem(key, JSON.stringify(initialValue));
				return initialValue;
			}
		} catch (error) {
			console.log(error);
			return initialValue;
		}
	});

	const [storedValue, setStoredValue] = useState<T>(() => initializer.current(key));

	const setValue = (value: T) => {
		try { 
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(key, JSON.stringify(value));
			}
			setStoredValue(value);
		} catch (error) {
			console.log(error);
		}
	};

	const removeStoredValue = () => {
		try {
			window.localStorage.removeItem(key);
			setStoredValue(initialValue);
		} catch (error) {
			console.log(error);
		}
	};

	return [storedValue, setValue, removeStoredValue];
};