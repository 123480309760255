'use client';

import { useState, PropsWithChildren } from 'react';
import { QueryClientProvider, QueryClient, QueryCache } from '@tanstack/react-query';
import { useRouter } from '@/navigation';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { CustomError } from '@nf/utils-common/errors';

function Providers({ children }: PropsWithChildren) {
	const router = useRouter();
	const [client] = useState(
		() => new QueryClient({
			queryCache: new QueryCache({
				onError: (error) => {
					if (error instanceof CustomError) {
						console.error(error.errorData);
					}

					console.error(error);
				}
			}),
		})
	);

	return (
		<QueryClientProvider client={client}>
			{children}
			{/*<ReactQueryDevtools initialIsOpen={false} />*/}
		</QueryClientProvider>
	);
}

export default Providers;