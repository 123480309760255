'use client';

import { useState, useEffect } from 'react';
import { useTheme } from 'next-themes';

import type { ColorSchemeData } from '@nf/types/site';
import { Maybe, MaybeMatch } from '@nf/utils-common/fpw/monadic';
import { siteService } from '@/services/site.service';
import { useSiteStore } from '@/store/site';
import { useAppearanceStore } from '@/store/appearance';
import { ViewEnum } from '@/types';

const trimStyleSpaces = (styles: string) => styles.replace(/\s+/g, ' ').trim();

export const ColorSchemeProvider = ({
	children
}: {
	children: React.ReactNode;
}) => {
	const { theme } = useTheme();
	const [skinColor] = useSiteStore(state => [state.deepLinkSiteInfo.skinColor]);
	const [schemeData, setSchemeData] = useState<ColorSchemeData>([]);
	const [darkScheme, lightScheme] = schemeData;
	const view = useAppearanceStore(state => state.view);

	useEffect(() => {
		const subscription = siteService.fetchColorScheme(skinColor, view).subscribe({
			next: async (result) => {
				const schemeResult = await result;
				const [darkSchemeEntity] = schemeResult.Data ?? [];
				Maybe(darkSchemeEntity)
					.chain(() => MaybeMatch(theme === 'dark'))
					.fold(
						() => { },
						() => document.documentElement.setAttribute('data-site-theme', darkSchemeEntity.siteTheme)
					);
				setSchemeData(schemeResult.Data ?? []);
			},
			error: err => {
				console.error('getColorScheme Error:', err);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [theme, skinColor, view]);

	// ⚠️ trimStyleSpaces: temporary workaround,
	// This code will be removed after upgrading turborepo in the future.
	return (
		<>
			{schemeData.length > 0 && view == ViewEnum.mars ? (
				<style jsx global>
					{`${trimStyleSpaces(`
						:root:not(#\\#):not(#\\#) {
							${convertStyleText(darkScheme)}
						}
					`)}`}
				</style>
			) : schemeData.length > 0 ? (
				<style jsx global>
					{`${trimStyleSpaces(`
						:root {
							${convertStyleText(darkScheme)}
						}
						
						[data-theme='light'] {
							${convertStyleText(lightScheme)}
						}
					`)}`}
				</style>
			) : null}
			{children}
		</>
	);
};

const convertStyleText = (colorScheme: ColorSchemeData[0]) => {
	if (colorScheme === undefined) {
		return '';
	}

	return Object.entries(colorScheme)
		.filter(([key]) => key !== 'siteTheme')
		.map(([key, value]) => `--${key}: ${value};`)
		.join('');
};