import { useEffect, useState } from 'react';
import { useSiteStore } from '@/store/site';
import { getAuthToken } from '@/store/auth';
import { ApiQueryBasePromise } from '@/types';
import { getResponseJwtHeader } from '@/utils/web';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useExternalScript } from '@nf/utils-common/hooks';
import { cloudUrlParser } from '@/utils/aws-domain-util';

export interface FpsRequest {
	di: string;
	bi: string;
	fdi: string;
	analysis: string;
}

export const useFps = (
	AgentSite: string | undefined,
	EnableFps: boolean | undefined,
	FpsJsUrl: string | undefined,
) => {
	const [hasCheckFps, setHasCheckFps] = useState(false);
	const [apiDomain] = useSiteStore(state => [state.apiDomain]);

	const fpsJsUrl = EnableFps ? FpsJsUrl : '';
	const getExternalJs = useExternalScript(fpsJsUrl ?? '');

	useEffect(() => {
		const isJsReady = getExternalJs === 'ready' && typeof getExternalJs !== 'undefined';
		if (!isJsReady) {
			return;
		}

		const fetchFpsHandler = async ({ di, bi, fdi, analysis }: FpsRequest): Promise<ApiQueryBasePromise<unknown>> => {
			const authToken = getAuthToken();

			const formData = new FormData();
			formData.append('__di', di);
			formData.append('__bi', bi);
			formData.append('__fdi', fdi);
			formData.append('detecas-analysis', analysis);

			const options = {
				body: formData
			};

			const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${apiDomain.ctcdDomain}/FpsHandler`), options)(authToken);

			if (!response.ok) {
				throw new Error(`${response.status}: ${response.statusText}`);
			}

			return {
				data: await response.text(),
				jwtToken: getResponseJwtHeader(response)
			};
		};

		const checkFps = (count: number) => {
			const __di = document.getElementById('__di') as HTMLInputElement | null;
			const __bi = document.getElementById('__bi') as HTMLInputElement | null;
			const __fdi = document.getElementById('__fdi') as HTMLInputElement | null;
			const __analysis = document.getElementById('detecas-analysis') as HTMLInputElement | null;

			if (__di != null || __bi != null || __fdi != null || __analysis != null) {
				fetchFpsHandler({
					di: __di?.value ?? '',
					bi: __bi?.value ?? '',
					fdi: __fdi?.value ?? '',
					analysis: __analysis?.value ?? ''
				});
			} else {
				if (count < 10) setTimeout(() => {
					checkFps(count + 1);
				}, 1000);
			}
		}

		const siteName = AgentSite?.toLowerCase();
		if (!hasCheckFps && siteName && !['12bet', 'soon88'].includes(siteName)) {
			checkFps(0);
			setHasCheckFps(true);
		}
	}, [apiDomain.ctcdDomain, getExternalJs, hasCheckFps, AgentSite]);
};
