import { of, filter, pairwise, Observable, BehaviorSubject } from 'rxjs';

import type { ApiObservablePromise } from '@/types';
import type { ApiResponse, ApiSuccessResponse } from '@nf/types/api';
import type { ExtendOddsTokenData } from '@nf/types/site';
import type { AccountTokenData, LoginCheckInData } from '@nf/types/account';
import { IO, Effect, Maybe } from '@nf/utils-common/fpw/monadic';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { convertApiLocale } from '@nf/utils-common/site';
import { fetchWithReactive, fetchAndGetJwtHeader } from '@/utils/web';
import { SessionContent, getUserSession } from '@nf/services/actions/auth/session';
import { siteService } from './site.service';

const invokeLoginCheckIn = (token: string, apiDomain: string | undefined): Observable<
	ApiObservablePromise<ApiResponse<LoginCheckInData | null>>
> => {
	const body = JSON.stringify({ SERVER_GROUP: siteService.getServerGroupEnvironmentValue() });
	return Effect(fetchComposer.postWithBearer(`${apiDomain}/Login/LoginCheckIn`, { body: body }))
		.map(fetchAndGetJwtHeader)
		.run(token);
};

type ExtendOptions = {
	at: string;
	rt: string;
	lang: string;
};

const invokeOddsTokenExtend = (token: string, apiDomain: string | undefined) => (options: ExtendOptions | null): Observable<
	ApiObservablePromise<ApiResponse<string | ExtendOddsTokenData | null>>
> => {
	return Effect(
		fetchComposer.postWithBearer(`${apiDomain}/Login/ExtendToken`, { body: JSON.stringify(options ?? {}) })
	)
		.map(fetchAndGetJwtHeader)
		.run(token);
};

type BeforeBody = {
	OddsType: number;
	SkinColor: string;
	Language: string;
};

/** deprecated */
const fetchVisitorToken = (locale: string, apiDomain: string | undefined): Observable<
	Promise<ApiSuccessResponse<AccountTokenData | null>>
> => {
	if (!apiDomain) return of(Promise.reject('apiDomain is empty'));

	return IO.of(
		fetchComposer.post<BeforeBody>(
			`${apiDomain}/api/Token/GenerateBeforeToken`,  
			{
				OddsType: 0,
				SkinColor: '',
				Language: convertApiLocale(locale)
			}
		)
	)
		.map(fetchWithReactive<ApiSuccessResponse<AccountTokenData>>)
		.run();
};

const checkUserToken = async (userName: string, siteName: string, uuid: string, updateAuthToken: (authToken: string) => void) => {
	const userSession = await getUserSession({
		name: userName,
		site: siteName,
		uuid: uuid
	});

	return Maybe(userSession).fold(
		() => false,
		(session: SessionContent) => {
			updateAuthToken(session.token);
			return true;
		}
	);
};

/* JWT token */
const jwtTokenUpdater$ = new BehaviorSubject<string | undefined>(undefined);

const updateJwtToken = (jwtToken: string) => jwtTokenUpdater$.next(jwtToken);

const onUpdateJwtToken = () => 
	jwtTokenUpdater$.asObservable()
		.pipe(
			pairwise(),
			filter(([previousToken, newToken]) => Boolean(newToken) && (previousToken !== newToken))
		);

const getJwtTokenValue = () => jwtTokenUpdater$.value;

export const authService = {
	invokeLoginCheckIn,
	invokeOddsTokenExtend,
	fetchVisitorToken,
	checkUserToken,
	updateJwtToken,
	onUpdateJwtToken,
	getJwtTokenValue,
};