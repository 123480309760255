import { useState, useCallback, Dispatch, SetStateAction } from 'react';

export function useToggle(
	defaultValue = false
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
	const [value, setValue] = useState(defaultValue);

	const toggle = useCallback(() => setValue(v => !v), []);

	return [value, toggle, setValue];
}