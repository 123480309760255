import { useEffect, useRef } from 'react';

export function useFirstRender(): boolean {
	const isFirstRender = useRef(true);
	
	useEffect(() => {
		isFirstRender.current = false;
	}, []);

	return isFirstRender.current;
}