'use client';

import { useEffect, useState, useRef } from 'react';
import { useParams } from 'next/navigation';
import { useRouter, usePathname } from '@/navigation';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { Subscription } from 'rxjs';

import type { AccountTokenData } from '@nf/types/account';
import { ApiErrorCode } from '@nf/utils-common/constants';
import { useSiteStore } from '@/store/site';
import { visitorAtom } from '@/atoms/visitor';
import { Maybe, MaybeMatch, matchEquals } from '@nf/utils-common/fpw/monadic';
import { siteService } from '@/services/site.service';
//import { useSwitchBackView } from '@/hooks';
import { useGetSiteConfigs, useGetSiteConfigsWithSession } from '@/hooks/query/use-site-configs';
import { useAuthData } from '@/store/auth';
import { useAppearanceStore } from '@/store/appearance'
import { fetchGetSkinMode, GetSkinModeUrlRequest } from '@/hooks/query/use-account-view';
import { switchViewService } from '@/services/swtich-view.service';
import { useAccountInfo } from '@/hooks/query/account';
import { SiteConfigsSettingsData } from '@nf/types/site';
import { RedirectType, redirect } from 'next/navigation';
import { getAuthToken } from '@/store/auth';
import { 
	RequestJwtTokenData, 
	fetchUseTokenLogin, 
	useRoutePermission
} from '@/hooks';

export const BeforeProvider = () => {
	const router = useRouter();
	const queryClient = useQueryClient();
	const params = useParams();
	const locale = params.locale as string;
	const pathName = usePathname();
	const isInOneLogin = pathName === '/onelogin';
	const isInDeepLink = pathName === '/deeplink';

	const [apiDomain, deepLinkSiteInfo] = useSiteStore(state => [state.apiDomain, state.deepLinkSiteInfo]);
	const visitorInfo = useAtomValue(visitorAtom);
	const setVisitorInfo = useSetAtom(visitorAtom);
	const useToken = getAuthToken() ?? visitorInfo?.AccessToken;
	const { isRedisTokenChecked } = useAuthData() ?? {};
	const { SiteConfigsData } = useGetSiteConfigs();
	const { SiteConfigsWithSessionData } = useGetSiteConfigsWithSession();
	//const { switchBackView } = useSwitchBackView();
	const view: number = useAppearanceStore(state => state.view);
	const { user } = useAccountInfo();
	const [redirectURL, setRedirectURL] = useState('');
	const ref = useRef<string | null>(null);

	let siteConfigSubscription: Subscription;

	useRoutePermission();

	const switchBackView = () => {
		const WebSkinTypeDefault = SiteConfigsWithSessionData?.WebSkinTypeDefault;// SiteConfigsData?.WebSkinTypeDefault;
		const platFrom = SiteConfigsWithSessionData?.SwitchFrom;

		const requestData = {
			PlatForm: platFrom,
			SkinMode: WebSkinTypeDefault
		}
		fetchGetSkinMode(apiDomain, useToken as string, requestData as GetSkinModeUrlRequest).then((url) => {
			let urlpath = url.data.Data as string;
			if (urlpath !== undefined) {
				const shortCode = switchViewService.convertLanguageToShortCode(user?.Lang as string, SiteConfigsData as SiteConfigsSettingsData);
				urlpath = switchViewService.appendDeepLink(deepLinkSiteInfo, shortCode, urlpath);
				setRedirectURL(urlpath);
				ref.current = urlpath;
			}
		})
	}

	useEffect(() => {
		if (ref.current !== null) {
			redirect(redirectURL, 'replace' as RedirectType);
		}
	}, [redirectURL])


	// get odds server token & url
	const getBeforeOddsServerConfig = (token: string) => {
		let newToken = token;
		siteConfigSubscription = siteService.fetchBeforeOddsServerConfig(token, apiDomain.ctcdDomain).subscribe({
			next: async (result) => {
				const data = await result.data;

				// Set the new JWT token
				Maybe(result.jwtToken).fold(
					() => {},
					(jwtToken: string) => {
						newToken = jwtToken;
					}
				);

				MaybeMatch(data.Success)
					.fold(
						() => {
							console.error(`getSiteConfig Error: ${data.Code}: ${data.Message}`);
						},
						() => {
							const settingData = data.Data;
							setVisitorInfo({
								AccessToken: newToken,
								OddsServerToken: settingData?.OddsServerToken,
								OddsServerUrl: settingData?.OddsServerUrl,
								OneAccessToken: settingData?.OneAuthenticationInfo?.AccessToken,
								OneRefreshToken: settingData?.OneAuthenticationInfo?.RefreshToken,
								ApiBackendUrl: settingData?.ApiBackendUrlInfo?.Url,
								IsShouldClearDomain: settingData?.ApiBackendUrlInfo?.IsShouldClearDomain,
							});
						}
					);
			},
			error: err => {
				console.error('getSiteConfig Error:', err);
			}
		});
	};

	// for deeplink to before
	useEffect(() => {
		if (isRedisTokenChecked && isInDeepLink && apiDomain.ctcdDomain) {
			Maybe(visitorInfo?.AccessToken)
				.fold(
					() => { },
					getBeforeOddsServerConfig
				);
		}
	}, [isRedisTokenChecked, apiDomain.ctcdDomain]);

	// for non-deeplink to before or deeplink (with lang) redirect to before 
	useEffect(() => {
		if (!isRedisTokenChecked || isInOneLogin || isInDeepLink || !apiDomain.ctcdDomain) return;

		const requestData = {
			apiDomain: apiDomain.ctcdDomain,
			language: locale,
			accessToken: visitorInfo?.AccessToken ?? 'unuse',
			isBefore: true,
			isExtend: visitorInfo?.AccessToken ? true : false,
			GalaxyUserServerGroup: siteService.getServerGroupEnvironmentValue()
		} as RequestJwtTokenData;

		fetchUseTokenLogin(requestData).then(tokenResult => {
			MaybeMatch(tokenResult.Success)
				.fold(
					() => {
						console.error(`getVisitorToken Error: ${tokenResult.Code}: ${tokenResult.Message}`);

						matchEquals(tokenResult.Code)
							.on(ApiErrorCode.UM, () => {
								queryClient.clear();
								router.replace('/um');
							})
							.on(ApiErrorCode.IpBlock, () => {
								queryClient.clear();
								router.replace('/limit');
							});
					},
					() => {
						Maybe(tokenResult.Data)
							.chain((tokenData: AccountTokenData) => Maybe(tokenData?.Token))
							.fold(
								() => { },
								getBeforeOddsServerConfig
							);
					}
				);
		});
	}, [locale, apiDomain.ctcdDomain, isRedisTokenChecked]);

	// unsubscribe subscription
	useEffect(() => {
		return () => {
			siteConfigSubscription?.unsubscribe();
		};
	}, []);

	// check webSkinType
	useEffect(() => {
		if (!(isInOneLogin || isInDeepLink) && !!SiteConfigsData?.WebSkinTypeAllow && !!view && SiteConfigsWithSessionData?.SwitchFrom != undefined) {
			siteService.webSkinTypeAllowCheck(SiteConfigsData?.WebSkinTypeAllow, view)
				.fold(
					() => switchBackView(),
					() => { }
				);
		}
	}, [SiteConfigsData?.WebSkinTypeAllow, view, SiteConfigsWithSessionData?.SwitchFrom, isInOneLogin, isInDeepLink]);

	return null;
};