import { useEffect, useRef } from 'react';
import { usePathname } from '@/navigation';
import { produce } from 'immer';
import { useAuthActions, useAuthData, useAuthToken } from '@/store/auth';
import { createUserSession, getUserSession } from '@nf/services/actions/auth/session';
import { useSearchParams } from 'next/navigation';
import { authService } from '@/services/auth.service';
import { TokenTypeEnum, getTokenTypeEnum } from '@/hooks/use-galaxy-deeplink-params';
import { convertNextSearchParamsToLowerCase } from '@nf/utils-common/url';

const safeTokenSliceLength = 30;

const addTokenToRecord = (tokens: Array<string>, token: string, amount: number = 12) => {
	return produce(tokens, draft => {
		if (!draft.includes(token)) {
			draft.push(token);

			if (draft.length > amount) {
				draft.shift();
			}
		}
	});
};

export const AuthProvider = () => {
	const pathname = usePathname();
	const { updateAuthToken, setIsRedisTokenChecked, signOut } = useAuthActions();
	const { userName, siteName, uuid, hasHydrated } = useAuthData() ?? {};
	const authToken = useAuthToken();
	const tokensRef = useRef(authToken ? [authToken.slice(-safeTokenSliceLength)] : []);
	const isInDeepLink = pathname === '/deeplink';
	const originalSearchParams = useSearchParams();
	const searchParams = convertNextSearchParamsToLowerCase(originalSearchParams);
	const isDeepLinkHasTokenType = isInDeepLink && getTokenTypeEnum(searchParams.get('tokentype')) != TokenTypeEnum.Default;

	// 一般 deeplink 登入流程皆有 tokenType 需清除暫存資訊, 若無 tokenType 代表 user 自己打的, 要保留登入狀態
	useEffect(() => {
		if (isDeepLinkHasTokenType) {
			localStorage.removeItem('persist:visitorInfo');
			localStorage.removeItem('persist:authStore');
			signOut();
		}
	}, [isDeepLinkHasTokenType]);

	// 檢查 authToken 是否存在
	useEffect(() => {
		if (authToken || !hasHydrated || isInDeepLink) return;

		if (userName && siteName && uuid) {
			authService.checkUserToken(userName, siteName, uuid, updateAuthToken).finally(() => setIsRedisTokenChecked(true));
		} else {
			setIsRedisTokenChecked(true);
		}
	}, [authToken, userName, siteName, uuid, hasHydrated, isInDeepLink]);

	// 當有 authToken 且 redis 無紀錄時，新增至 redis
	useEffect(() => {
		if (!authToken || !userName || !siteName || !uuid || isInDeepLink) return;

		const isRecordedToken = tokensRef.current.includes(authToken);

		if (isRecordedToken) return;

		tokensRef.current = addTokenToRecord(
			tokensRef.current,
			authToken.slice(-safeTokenSliceLength)
		);

		const updateToken = async () => {
			try {
				// Get the token of current user from Redis,
				// If the token to be updated is the same as the one in Redis, there is no need to update!
				const userSession = await getUserSession({
					name: userName,
					site: siteName,
					uuid
				});

				if (userSession?.token === authToken) {
					return;
				}

				const redisResponse = await createUserSession({
					name: userName,
					site: siteName,
					uuid
				}, {
					token: authToken
				});

				if (!redisResponse.success) {
					throw new Error('Error: Update token to redis fail');
				}
			} catch (error: any) {
				console.error(error.message);
			}
		};

		updateToken();
	}, [authToken, userName, siteName, uuid, isInDeepLink]);

	return null;
};