import { Observable } from 'rxjs';
import type { ApiSuccessResponse } from '@nf/types/api';
import type { ApiObservablePromise } from '@/types';
import type { ColorSchemeData, SiteConfigsSettingsData, OddsServerSettingData } from '@nf/types/site';
import { useSiteStore } from '@/store/site';
import { WebSkinType } from '@nf/utils-common/constants';
import { Maybe, MaybeMatch, Effect, IO, Box } from '@nf/utils-common/fpw/monadic';
import { split } from '@nf/utils-common/fpw/utils';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { fetchWithReactive, fetchAndGetJwtHeader } from '@/utils/web';
import { ViewEnum } from '@/types';

const fetchColorScheme = (skinColor: string, view: ViewEnum): Observable<
	ApiSuccessResponse<ColorSchemeData>
> => {
	const { apiDomain } = useSiteStore.getState();

	let queryString = '';
	if (skinColor) {
		const skinColorParam = skinColor.includes('_') ? skinColor : `_${skinColor}`;
		queryString = `?skinColor=${skinColorParam}`;
	}
	const methodName = view == ViewEnum.mars ? 'GetMarsAutoColorSchemes' : 'GetAutoColorSchemes'; 
	return IO.of(fetchComposer.get(`${apiDomain.ctcdDomain}/api/AutoColorScheme/${methodName}${queryString}`, {}))
		.map(fetchWithReactive<ApiSuccessResponse<ColorSchemeData>>)
		.run();
};

const fetchBeforeOddsServerConfig = (token: string, apiDomain: string | undefined): Observable<
	ApiObservablePromise<ApiSuccessResponse<OddsServerSettingData>>
> => {
	return Effect(fetchComposer.getWithBearer(`${apiDomain}/api/Config/GetBeforeOddsServerConfig`))
		.map(fetchAndGetJwtHeader<ApiSuccessResponse<OddsServerSettingData>>)
		.run(token);
};
const getCombinedImagePathWithoutVersionId = (imageStr: string, isGalaxyFolder: boolean, imageServerUrl: string): string => {
	const urlSegments: string[] = [];
	if (imageServerUrl) {
		urlSegments.push(imageServerUrl.endsWith('/') ? imageServerUrl.substring(0, imageServerUrl.length-1) : imageServerUrl);
	}
	if (isGalaxyFolder) {
		urlSegments.push('galaxy');
	}
	urlSegments.push(imageStr.startsWith('/') ? imageStr.substring(1) : imageStr);
	return urlSegments.join('/');
}
const getVersionId = () => {
	const fileVersion = process.env.NEXT_PUBLIC_FILE_VERSION_ID;
	return `v=${fileVersion}`;
};
const combineImagePath = (imageStr: string, isGalaxyFolder: boolean, siteConfigsData?: SiteConfigsSettingsData | null) => {
	if (!siteConfigsData?.EnableImageServer) {
		return imageStr;
	}
	return `${getCombinedImagePathWithoutVersionId(imageStr, isGalaxyFolder, siteConfigsData.ImageServerUrl)}?${getVersionId()}`
};

const getServerGroupEnvironmentValue = () => {
	const { ServerGroup } = useSiteStore.getState();
	return ServerGroup;
};

const isSupportGalaxy = (skinMode: number, skinTypes: Array<number>) => 
	MaybeMatch(skinTypes.includes(skinMode) || skinMode === -1);

const isSupportedSkin = (skin: number) => (skinTypes: Array<number>) => 
	MaybeMatch(skinTypes.includes(skin) || skin === -1);

const splitAllowedWebSkinType = (allowedWebSkinType: string | undefined) => Box.of(allowedWebSkinType)
	.map(split(','))
	.map((skinTypes: Array<string>) => skinTypes.map(Number))
	.extract();

const webSkinTypeAllowCheck = (allowedWebSkinType: string | undefined, skinMode: number | undefined) => {
	return Maybe(allowedWebSkinType)
		.map(splitAllowedWebSkinType)
		.chain((skinTypes: number[]) => {
			if (skinMode !== undefined) {
				return isSupportGalaxy(skinMode, skinTypes);
			} else {
				return MaybeMatch(undefined);
			}
		});
};

const skinCheck = (allowedWebSkinType: string | undefined, skin: number) => Maybe(allowedWebSkinType)
	.map(splitAllowedWebSkinType)
	.chain(isSupportedSkin(skin))
	.chain(() => isSupportGalaxy(skin, [7, 8]));

const isAppBarMeActivedPath = (pathname: string) => {
	const regex = /^\/(?:promotion|lobby|bets)(?:\/|$)/;
	return regex.test(pathname);
};

export const siteService = {
	fetchColorScheme,
	fetchBeforeOddsServerConfig,
	getCombinedImagePathWithoutVersionId,
	getVersionId,
	combineImagePath,
	webSkinTypeAllowCheck,
	skinCheck: skinCheck,
	getServerGroupEnvironmentValue,
	isAppBarMeActivedPath,
};