import webStorageKeys from '@/constants/web-storage-keys';
import { convertCultureToRef } from '@/utils/common-util';
import { BehaviorSubject, Subject } from 'rxjs';

const profilePanel$ = new BehaviorSubject(false);
const displayProfilePanel = (isShow: boolean, isFromAppBar?: boolean) => {
	profilePanel$.next(isShow);

	const isShowBack = (!isShow || isFromAppBar) ? 'false' : 'true';
	sessionStorage.setItem(webStorageKeys.ME_PAGE_SHOW_BACK, isShowBack);
}

const galaxyTutorialHint$ = new Subject();
const displayGalaxyTutorialHint = (isShow?: boolean) => galaxyTutorialHint$.next(isShow);

const redirectToDepositUrl = (url: string | undefined) => {
	if (!url) return;
	window.location.href = url;
}

const getRnRUrl = (rnrUrl: string | undefined, locale: string, isDarkMode: boolean | undefined, siteId: string | undefined, isApp: boolean | undefined) => {
	if (!rnrUrl) return '';

	const userLang = convertCultureToRef(locale);

	const url = new URL(rnrUrl.replace('{0}', userLang));
	url.protocol = location.protocol;

	url.searchParams.append('new-window', '1'); // 導轉或另開都需要加的參數

	if (isDarkMode) {
		url.searchParams.append('darkmode', '1');
	}

	const isIvory = siteId === '4304900'; // sitename=N8I (ivorysports)
	if (isIvory && userLang === 'en') {
		url.searchParams.append('isIvory', '1');
	}

	if (isApp) {
		url.searchParams.append('returnUrl', encodeURIComponent(window.location.href));
	}

	return url.href;
}

const checkCanBack = () => sessionStorage.getItem(webStorageKeys.ME_PAGE_SHOW_BACK) === 'true';

export const profilePanelService = {
	onProfilePanel$: profilePanel$.asObservable(),
	displayProfilePanel,
	onGalaxyTutorialHint$: galaxyTutorialHint$.asObservable(),
	displayGalaxyTutorialHint,
	redirectToDepositUrl,
	getRnRUrl,
	checkCanBack
};