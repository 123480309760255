import { useEffect, RefObject } from 'react';

type Handler = (event: MouseEvent) => void;

export function useOuterClick<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>, 
	handler: Handler
): void {
	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (ref.current && 
				!ref.current.contains(event.target as Node)
			) {
				handler(event);
			}
		};

		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);
	}, [ref, handler]);
}