import { useEffect, useRef, useState } from 'react';
import { useRouter, usePathname } from '@/navigation';
import { useTheme } from 'next-themes';
import { AccountActStatus } from '@nf/utils-common/constants';
import { getSiteProtectedPaths } from '@/services/settings.service';
import { siteService } from '@/services/site.service';
import { useAccountInfo } from '@/hooks/query/account';
import { useRoutePermission } from '@/hooks';
import { useGetSiteConfigs, useGetSiteConfigsWithSession } from '@/hooks/query/use-site-configs';
import { useFps } from '@/hooks/use-fps';
import { fetchGetSkinMode, GetSkinModeUrlRequest } from '@/hooks/query/use-account-view';
import { useSiteStore } from '@/store/site';
import { SiteConfigsSettingsData } from '@nf/types/site';
import { switchViewService } from '@/services/swtich-view.service';
import { RedirectType, redirect } from 'next/navigation';
import { useAppearanceStore } from '@/store/appearance';
import { getAuthToken } from '@/store/auth';
import { UserInfo } from '@nf/types/account';

// Get paths that remove the first slash
const protectedPaths = getSiteProtectedPaths();

// 使用者資訊載入後代入theme資訊, 只初次載入一次
const useInitUserTheme = (user: UserInfo | null | undefined) => {
	const { theme, setTheme } = useTheme();
	const [isInitUserTheme, setIsInitUserTheme] = useState(false);

	useEffect(() => {
		if (!isInitUserTheme && user) {
			const userTheme = user.DarkMode ? 'dark' : 'light';
			if (userTheme !== theme) {
				setTheme(userTheme);
			}
			setIsInitUserTheme(true);
		}	
	}, [user]);
}


export const AccountProvider = () => {
	const { SiteConfigsData } = useGetSiteConfigs();
	const { SiteConfigsWithSessionData } = useGetSiteConfigsWithSession();
	const [apiDomain, deepLinkSiteInfo] = useSiteStore(state => [state.apiDomain, state.deepLinkSiteInfo]);
	const router = useRouter();
	const pathname = usePathname();
	const isInOneLogin = pathname === '/onelogin';
	const isInDeepLink = pathname === '/deeplink';
	const { user } = useAccountInfo();
	const ref = useRef<string | null>(null);
	const [redirectURL, setRedirectURL] = useState('');
	const view: number = useAppearanceStore(state => state.view);
	const authToken = getAuthToken();

	useRoutePermission();
	useInitUserTheme(user);
	useFps(user?.AgentSite, SiteConfigsData?.EnableFps, SiteConfigsData?.FpsJsUrl);

	const switchBackView = () => {
		const WebSkinTypeDefault = SiteConfigsWithSessionData?.WebSkinTypeDefault;// SiteConfigsData?.WebSkinTypeDefault;
		const platFrom = SiteConfigsWithSessionData?.SwitchFrom;

		const requestData = {
			PlatForm: platFrom,
			SkinMode: WebSkinTypeDefault
		}
		fetchGetSkinMode(apiDomain, authToken as string, requestData as GetSkinModeUrlRequest).then((url) => {
			let urlpath = url.data.Data as string;
			if (urlpath !== undefined) {
				const shortCode = switchViewService.convertLanguageToShortCode(user?.Lang as string, SiteConfigsData as SiteConfigsSettingsData);
				urlpath = switchViewService.appendDeepLink(deepLinkSiteInfo, shortCode, urlpath);
				setRedirectURL(urlpath);
				ref.current = urlpath;
			}
		})
	}

	useEffect(() => {
		if (ref.current !== null) {
			redirect(redirectURL, 'replace' as RedirectType);
		}
	}, [redirectURL])

	useEffect(() => {
		const isProtectedPath = protectedPaths.notDeposit.some(path => pathname.startsWith(path));

		if (user?.ActStatus === AccountActStatus.NotDeposit && isProtectedPath) {
			router.replace('/live');
		}
	}, [user?.ActStatus, pathname]);

	
	

	useEffect(() => {
		if (!(isInOneLogin || isInDeepLink) && !!SiteConfigsData?.WebSkinTypeAllow && !!view && SiteConfigsWithSessionData?.SwitchFrom != undefined && !!authToken) {
			siteService.webSkinTypeAllowCheck(SiteConfigsData?.WebSkinTypeAllow, view)
				.fold(
					() => switchBackView(),
					() => {}
				);
		}
	}, [SiteConfigsData?.WebSkinTypeAllow, view, SiteConfigsWithSessionData?.SwitchFrom, authToken, isInOneLogin, isInDeepLink]);

	return null;
};