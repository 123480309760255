'use client';

import { useLocale } from 'next-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

import type { ExtendOddsTokenData } from '@nf/types/site';
import type { VisitorInfo } from '@nf/types/account';
import { visitorAtom, updateVisitorAtom } from '@/atoms/visitor';
import { useSiteStore } from '@/store/site';
import { useAuthActions } from '@/store/auth';
import { Maybe, MaybeMatch, identity } from '@nf/utils-common/fpw/monadic';
import { useInterval } from '@nf/utils-common/hooks';
import { authService } from '@/services/auth.service';
import { accountService } from '@/services/account.service';
import { useGetSiteConfigs } from '@/hooks/query/use-site-configs';
import { useAccountInfo } from '@/hooks/query/account';

/** 注意: 目前 before 設定為小於 jwt token 效期 10 mins 的時間，若欲調整需同步異動後端 middleware 判斷延展效期的時間區間 */
const syncPeriodBefore = 9 * 60 * 1000;
const syncPeriod = 10 * 60 * 1000;

interface OddsTokenInfo {
	OddsServerToken: string;
	AccessToken?: string;
	RefreshToken?: string;
}

export const useSyncOddsToken = (userToken: string | undefined, isBefore: boolean) => {
	const queryClient = useQueryClient();
	const locale = useLocale();
	const apiDomain = useSiteStore(state => state.apiDomain);
	const { updateAuthToken } = useAuthActions();
	const visitorInfo = useAtomValue(visitorAtom);
	const updateVisitorInfo = useSetAtom(updateVisitorAtom);
	const { SiteConfigsData } = useGetSiteConfigs();
	const { user } = useAccountInfo();
	const isEnableApiBackend = Boolean(SiteConfigsData?.EnableApiBackend);

	const getSyncPeriod: number | null = Maybe(userToken && apiDomain.ctcdDomain).fold(
		() => null,
		() => isBefore ? syncPeriodBefore : syncPeriod
	);

	useInterval(() => {
		const payload = isEnableApiBackend ? {
			at: (isBefore ? visitorInfo?.OneAccessToken : user?.AccessToken) ?? '',
			rt: (isBefore ? visitorInfo?.OneRefreshToken : user?.RefreshToken) ?? '',
			lang: locale
		} : null;

		Maybe(userToken).fork({
			just: (token: string) => {
				authService.invokeOddsTokenExtend(token, apiDomain.ctcdDomain)(payload).subscribe({
					next: async (result) => {
						const data = await result.data;
						let newJwtToken: string | undefined = userToken;

						// Set the new JWT token
						Maybe(result.jwtToken).fold(
							identity,
							(jwtToken: string) => {
								isBefore
									? (newJwtToken = jwtToken)
									: updateAuthToken(jwtToken);
							}
						);

						MaybeMatch(data.ErrorCode === 0)
							.fold(
								() => {
									console.error(`SyncOddsToken Error: ${data.ErrorCode}: ${data.ErrorMsg}`);
								},
								() => {
									const oddsToken = data.Data ?? undefined;

									const oneAuthenticationInfo: OddsTokenInfo =
										MaybeMatch(isEnableApiBackend)
											.fold(
												() => ({
													OddsServerToken: oddsToken as string
												}),
												() => {
													const extendOddsTokenData = oddsToken as ExtendOddsTokenData;
													return {
														OddsServerToken: extendOddsTokenData.ot,
														AccessToken: extendOddsTokenData.at,
														RefreshToken: extendOddsTokenData.rt
													};
												}
											);

									MaybeMatch(isBefore).fold(
										() => {
											accountService.updateAccountInfo(queryClient, {
												...oneAuthenticationInfo
											});
										},
										() => {
											const tokenInfo: Partial<VisitorInfo> = {
												OddsServerToken: oneAuthenticationInfo.OddsServerToken
											};

											if (isEnableApiBackend) {
												tokenInfo.OneAccessToken = oneAuthenticationInfo.AccessToken;
												tokenInfo.OneRefreshToken = oneAuthenticationInfo.RefreshToken
											};

											updateVisitorInfo({
												AccessToken: newJwtToken,
												OddsServerToken: tokenInfo.OddsServerToken,
												OneAccessToken: tokenInfo.OneAccessToken,
												OneRefreshToken: tokenInfo.OneRefreshToken
											});
										}
									);
								}
							);
					},
					error: err => {
						console.error('SyncOddsToken Error:', err);
					}
				});
			},
			none: () => { }
		});
	}, getSyncPeriod);
}