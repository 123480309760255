import { useState, useEffect } from 'react';

export type ScriptStatus = 'idle' | 'loading' | 'ready' | 'error';

export interface UseExternalScriptOption {
	removeWhenUnmount?: boolean;
}

export const useExternalScript = (src: string, option?: UseExternalScriptOption) => {
	const [status, setStatus] = useState<ScriptStatus>(src ? 'loading' : 'idle');

	useEffect(() => {
		if (!src) {
			setStatus('idle');
			return;
		}

		let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

		if (!script) {
			script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.setAttribute('data-status', 'loading');
			document.body.appendChild(script);

			const setDataStatus = (event: Event) => {
				const scriptStatus: ScriptStatus = event.type === 'load' ? 'ready' : 'error';
				script?.setAttribute('data-status', scriptStatus);
			};

			script.addEventListener('load', setDataStatus);
			script.addEventListener('error', setDataStatus);
		} else {
			setStatus(
				script.getAttribute('data-status') as ScriptStatus | undefined ?? 'loading'
			);
		}

		const setStateStatus = (event: Event) => {
			const newStatus: ScriptStatus = event.type === 'load' ? 'ready' : 'error';
			setStatus(newStatus);
		};

		script.addEventListener('load', setStateStatus);
		script.addEventListener('error', setStateStatus);
		
		return () => {
			if (script) {
				script.removeEventListener('load', setStateStatus);
				script.removeEventListener('error', setStateStatus);

				if (option?.removeWhenUnmount) {
					script.remove();
				}
			}
		};
	}, [src, option?.removeWhenUnmount]);

	return status;
};