'use client';

import { useAuthData, useAuthToken } from '@/store/auth';
import { AccountProvider } from '../account-provider';
import { BeforeProvider } from '../before-provider';
import { SyncProvider } from '../sync-provider';
import { AuthProvider } from '../auth-provider';
import { OneDomainProvider } from '../one-domain-provider';
import { GTMProvider } from '../gtm-providers';
import { AppearanceProvider } from '../appearance-provider';
import { KafkaProvider } from '../kafka-provider';
import { NextAnalyseCleaner, NextAnalyseProvider, useIsNextAnalyseEnabled } from '@/features/next-analyse';

export const SiteProviders = () => {
	const authToken = useAuthToken();
	const { isRedisTokenChecked } = useAuthData() ?? {};
	const isNextAnalyseEnabled = useIsNextAnalyseEnabled();

	return (
		<>
			<AppearanceProvider />
			<AuthProvider />
			<OneDomainProvider />
			{isRedisTokenChecked && authToken ? <AccountProvider /> : <BeforeProvider />}
			<SyncProvider authToken={authToken} />
			<GTMProvider />
			<KafkaProvider />
			{
				isNextAnalyseEnabled ? <NextAnalyseProvider /> : <NextAnalyseCleaner />
			}
		</>
	);
};